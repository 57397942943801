import styles from './styles.module.scss';
import logoImage from '@/shared/images/logoRotoplas@2x.png';
import { useForm } from 'react-hook-form';
import CONST_REDIRECTS from '@/shared/consts/CONST_REDIRECTS.json';
import { useRouter } from 'next/router';
import { api_login, api_tokenInstrospection } from '@/shared/api_calls/token';
import { apiRoot, ctCustomer } from '@/shared/client';
import ErrorMessage from '../checkout/components/ErrorMessage';
import { Toast, notifyError } from '@/shared/components/toast/Toast';
import { useEffect, useState } from 'react';
import { findCookie, getCookieValue } from '@/shared/functions/cookies';
import { logout } from '@/shared/functions/logout';
import footerStyles from '@/shared/components/mainLayout/styles.module.scss';
import { ArrowBackIcon, BlindEyeIcon, OpenEyeIcon } from '@/shared/icons';
import Button from '@/shared/components/button/Button';
import { encryptWithAES } from '@/shared/functions/encrypt';
import { limitCredit } from '@/shared/api_calls/sap';
import Loader from '@/shared/components/loader/Loader';


import CustomFooter from './CustomFooter';
type FormValues = {
  email: string;
  password: string;
};

const Login = () => {
  const router = useRouter();
  const [togglePasswordView, setTogglePasswordView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm<FormValues>();
  const [shouldRender, setShouldRender] = useState(false);

  // Icon Eye
  const [changeIcon, setChangeIcon] = useState(false);
  // Password recovery
  const [passwordRecovery, setPasswordRecovery] = useState(false);

  const validateUser = async () => {
    const tokenCookie = findCookie('token');
    if (!tokenCookie) {
      setShouldRender(true);
      return;
    }
    const token = getCookieValue(tokenCookie);
    const resLogin = await api_tokenInstrospection(token);
    //bad token? delete and refresh
    if (resLogin.status !== 200) {
      logout();
    }
    //if logged, go back
    router.back();
  };

  useEffect(() => {
    validateUser();
  }, []);

  const emailWatcher = watch('email');
  const passwordWatcher = watch('password');

  let intents = 0
  const onSubmit = async (values: { email: string; password: string }) => {
    try {
      setIsLoading(true)
      const resLogin = await api_login(values.email, values.password);
      if (resLogin.status >= 500) {
        console.error('error', resLogin);
        throw 'hubo un error, intenta mas tarde';  
      }
      debugger
      if (resLogin.status !== 200) {
        setError('password', { type: 'invalidPassword', message: 'usuario y/o contraseña invalido' });
        setError('email', { type: 'invalidPassword', message: 'usuario y/o contraseña invalido' });
        const cIntents = findCookie('intents');
        if(cIntents){
          const cIntentsValue = getCookieValue(cIntents)
          const splitIntets = cIntentsValue.trim().split(',')
          intents = parseInt(splitIntets[0])
          const horaCreada = new Date(parseInt(splitIntets[1])).getTime()
          const diferencia = new Date().getTime() - horaCreada
          if (diferencia <= 180000 &&  intents >= 3) { // debe pasar 3 minutos
            throw "Recibimos demasiadas solicitudes de inicio de sesión. Espere e intente nuevamente más tarde"
          }
          if (diferencia >= 180000) intents = 0
        }
        intents++
        document.cookie = `intents=${intents},${new Date().getTime()}`
        throw "usuario y/o contraseña incorrectos, intenta nuevamente..."
      }
      clearErrors('email');
      clearErrors('password');

      document.cookie = `token=${resLogin.data.access_token}; max-age=172800`;
      const customerLogin = await apiRoot
        // .inStoreKeyWithStoreKeyValue({ storeKey: STORE_KEY })
        .login()
        .post({
          body: {
            email: values.email,
            password: values.password,
          },
        })
        .execute();
      let bussinesParent = "";
      if (!customerLogin.statusCode || customerLogin.statusCode >= 300) throw "";
      const bussinesbyIdCustomer = await apiRoot
        .asAssociate()
        .withAssociateIdValue({ associateId: customerLogin.body.customer.id })
        .businessUnits()
        .get()
        .execute();
      if (!bussinesbyIdCustomer.statusCode || bussinesbyIdCustomer.statusCode >= 300) throw "";
      debugger
      if(!bussinesbyIdCustomer || bussinesbyIdCustomer?.body?.results?.length == 1){
        bussinesParent = bussinesbyIdCustomer?.body?.results?.[0]?.parentUnit?.key || ""
      } else {
        bussinesParent = bussinesbyIdCustomer?.body?.results?.[0]?.topLevelUnit?.key
      }
      const externalId = customerLogin.body.customer.externalId
      const getLimitCredito = await limitCredit({ customerExternalId: externalId || ""  })
      if(getLimitCredito.codeBloqueo == 1) throw "Comunicate con nosotros para validar el estatus de tu cuenta."
      const encryptedLogin = encryptWithAES(
        `${values.email}, ${values.password}, ${bussinesParent}, ${customerLogin.body.customer.id}, ${externalId}`,
      );
      document.cookie = 'login=' + encryptedLogin+'; max-age=172800';
      router.push(CONST_REDIRECTS.HOME);
    } catch (err: any) {
      console.error('error: ', err);
      console.log(err)
      notifyError(err)
  


    }
    finally {
      setIsLoading(false)
    }
  };

  return (
    <>
      {shouldRender && (
        <>
        {isLoading && <Loader message=''/>}
        <Toast />
          <div className={styles['main']}>
            <div className={styles['modal-login']}>
              <div className={styles['grid']}>
                {/* <div className={styles['information']}> */}
                {passwordRecovery ? (
                  <>
                    <div className={styles['title']}>
                      <h1>¿Has olvidado tu contraseña?</h1>
                    </div>
                    <div className={styles['custom-content']}>
                      {/* <p>
                        ¡No te preocupes! Te enviaremos un enlace de recuperación a tu correo electrónico de inmediato
                        para que puedas restablecerla sin contratiempos.
                        <br />
                        <br />
                        Para consultas adicionales o reportes de algún problema, no dudes en ponerte en contacto con tu
                        asesor o enviar un correo electrónico a servicioaclientes@rotoplas.com.
                      </p> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles['title']}>
                      <h1>¡Bienvenido, aliado Rotoplas!</h1>
                    </div>
                    <div className={styles['custom-content']}>
                      <p>
                        Conoce y explora este nuevo portal e impulsa tu negocio hacia el éxito. Descubre un mundo de
                        oportunidades diseñadas para ti, desde la gestión y seguimiento de pedidos hasta ofertas
                        exclusivas y las últimas novedades en productos.
                        <br />
                        <br />
                        ¡Construyamos juntos un futuro más hidratado y sostenible para todos!
                      </p>
                    </div>
                  </>
                )}
                {/* </div> */}
                <div className={styles['login']}>
                  <div className={styles['box-image']}>
                    <img src={logoImage.src} alt="brand" style={{ width: '198px', height: '68px' }} />
                  </div>

                  {passwordRecovery ? (
                    <>
                      <h3>Recupera tu contraseña</h3>
                      <p
                        style={{
                          textAlign: 'left',
                          color: '#002254',
                          width: '100%',
                          paddingTop: '20px',
                          marginLeft: '16px',
                          paddingRight: '16px',
                        }}
                      >
                        <p>Si has olvidado tu contraseña, ¡No te preocupes! Escríbenos a <span style={{ fontWeight: 'bold' }}>ventasecom@rotoplas.com</span> solicitando la actualizacion.</p>
                        <p>Recibirás un correo con tus credenciales actualizadas. ¡Asi de facil!</p>
                      </p> 
                      <div
                        style={{ display: 'flex', justifyContent: 'center', marginTop: '158px', marginBottom: '5px' }}
                      >
                        <div style={{ marginTop: '4px', marginRight: '6px' }}>
                          <ArrowBackIcon />
                        </div>
                        <a
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: '#165EEB',
                            textDecoration: 'underLine',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setPasswordRecovery(!passwordRecovery);
                          }}
                        >
                          Volver
                        </a>
                      </div>
                    </>
                  ) : (
                    // Login
                    <>
                      <h3>Inicia sesión</h3>
                      <form className={styles['form']} onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles['box-inputs']}>
                          <label placeholder="Correo Electronico">Correo electrónico*</label>
                          <input
                            type="text"
                            placeholder="Correo electrónico *"
                            {...register('email', {
                              required: true,
                            })}
                            className={errors.email ? styles['invalid'] : ''}
                          />
                        </div>
                        <div className={styles['box-inputs']}>
                          <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
                            <label placeholder="Password">Contraseña*</label>
                          </div>
                          <div style={{ position: 'relative', width: '100%' }}>
                            <input
                              type={togglePasswordView ? 'text' : 'password'}
                              style={{ width: '100%' }}
                              placeholder="Contraseña *"
                              {...register('password', {
                                required: true,
                              })}
                              className={errors.password ? styles['invalid'] : ''}
                            />
                            <div
                              style={{
                                position: 'absolute',
                                right: '12px',
                                top: '40%',
                                cursor: 'pointer',
                                width: '14px',
                                height: '12px',
                              }}
                              onClick={() => {
                                setTogglePasswordView(!togglePasswordView);
                                setChangeIcon(!changeIcon);
                              }}
                            >
                              {changeIcon ? <OpenEyeIcon /> : <BlindEyeIcon />}
                            </div>
                          </div>
                          {errors.password?.type === 'invalidPassword' && (
                            <ErrorMessage>usuario y/o contraseña invalidos</ErrorMessage>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '20px',
                              marginBottom: '0',
                            }}
                          >
                            <a
                              style={{
                                fontSize: '12px',
                                color: '#165EEB',
                                textDecoration: 'underLine',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setPasswordRecovery(!passwordRecovery);
                              }}
                            >
                              Olvidé mi contraseña
                            </a>
                          </div>
                        </div>

                        <Button
                          variant="secondary"
                          style={{
                            height: '50px',
                            width: '324px',
                            borderColor:
                              !emailWatcher || emailWatcher === '' || !passwordWatcher || passwordWatcher === ''
                                ? 'gray'
                                : '#165EEB',
                            backgroundColor:
                              !emailWatcher || emailWatcher === '' || !passwordWatcher || passwordWatcher === ''
                                ? 'gray'
                                : '#165EEB',
                          }}
                          type="submit"
                          disabled={
                            !emailWatcher || emailWatcher === '' || !passwordWatcher || passwordWatcher === ''
                              ? true
                              : false
                          }
                        >
                          Iniciar sesión
                        </Button>
                        <a href='https://46477166.hs-sites.com/distribuidores' target='_blank'>
                          <Button
                            variant="secondary"
                            style={{
                              height: '50px',
                              width: '324px',
                              marginTop: '16px',
                              borderColor: '#165EEB',
                              backgroundColor: '#165EEB',
                            }}
                          >
                            Quiero ser distribuidor
                          </Button>
                        </a>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={footerStyles['footer']}>
            <CustomFooter/>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
